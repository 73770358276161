import { useLocalization } from '../../../common/providers/LocaleProvider';
export var useAltText = function (alt, label) {
    var t = useLocalization().t;
    if (!!alt) {
        return alt;
    }
    if (!!label) {
        return label;
    }
    return t('h24_entrypoint_icon_alt');
};
