var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import Toolbar from '../../toolbar/components/Toolbar';
import TopNavigationBar from '../../top-navigation-bar/components/TopNavigationBar';
import UspBar from '../../usp-bar/components/UspBar';
var GlobalSectionHeaderNavigation = function (_a) {
    var uspBar = _a.uspBar, navigation = _a.navigation, _b = _a.searchParams, searchParams = _b === void 0 ? {} : _b, _c = _a.searchOptions, searchOptions = _c === void 0 ? {} : _c, restProps = __rest(_a, ["uspBar", "navigation", "searchParams", "searchOptions"]);
    return (React.createElement(React.Fragment, null,
        uspBar && React.createElement(UspBar, __assign({}, uspBar)),
        React.createElement(Toolbar, __assign({ searchParams: searchParams, searchOptions: searchOptions }, restProps)),
        navigation && React.createElement(TopNavigationBar, { navigation: navigation.primary })));
};
export default GlobalSectionHeaderNavigation;
