var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { display, alignItems } from 'styled-system';
import { Text as TextBase } from '../../../common/components/Text';
var Text = styled(TextBase)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n"])), display, alignItems);
Text.defaultProps = {
    as: 'p',
    display: 'flex',
    alignItems: 'center',
    m: 'sp_0',
    py: ['sp_4', null, 'sp_6'],
    color: 'sys.neutral.text.inverted',
    fontSize: ['fs_12', null, 'fs_14'],
    lineHeight: ['lh_18', 'lh_20'],
};
export default Text;
var templateObject_1;
