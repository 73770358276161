var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from '@emotion/styled';
import { height, space, width } from 'styled-system';
import { useAltText } from './hooks/useAltText';
var ICON_SIZE = 16;
var Icon = styled.img(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n"])), height, width, space);
Icon.defaultProps = {
    height: ICON_SIZE,
    width: ICON_SIZE,
};
var EntryIcon = function (_a) {
    var label = _a.label, url = _a.url, alt = _a.alt;
    var altText = useAltText(alt, label);
    return React.createElement(Icon, { src: url, alt: altText, mr: label ? 'sp_8' : 'sp_0' });
};
export default EntryIcon;
var templateObject_1;
