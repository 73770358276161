var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { space, color, display, fontSize, lineHeight, alignItems, themeGet, } from 'styled-system';
import { Link as LinkBase } from '../../../common/components/Link';
import { textDecoration } from '../../../common/theme/system-utilities';
var Link = styled(LinkBase)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  &:active,\n  &:hover,\n  &:focus {\n    ", ";\n    text-decoration: underline;\n    text-decoration-color: ", ";\n  }\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  &:active,\n  &:hover,\n  &:focus {\n    ", ";\n    text-decoration: underline;\n    text-decoration-color: ", ";\n  }\n"])), space, color, display, fontSize, alignItems, lineHeight, textDecoration, color, themeGet('colors.sys.neutral.text.inverted'));
Link.defaultProps = {
    m: 'sp_0',
    py: ['sp_4', null, 'sp_6'],
    display: 'flex',
    alignItems: 'center',
    color: 'sys.neutral.text.inverted',
    fontSize: ['fs_12', null, 'fs_14'],
    lineHeight: ['lh_18', 'lh_20'],
    textDecoration: 'none',
};
export default Link;
var templateObject_1;
